import ErrorView from '$/containers/Views/Error';
import themes from '$/styles/themes';
import { ThemeProvider } from 'styled-components';

export default function NotFound(): JSX.Element {
  // Opinionated: do not record an exception in Sentry for 404
  return (
    <ThemeProvider theme={themes.light}>
      <ErrorView type="error" />
    </ThemeProvider>
  );
}
